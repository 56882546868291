import React from "react";
import GrayDivisor from "./grayDivisor";

export const About : React.FC = () => {
    return (
      <div>
        <GrayDivisor text="About" />
        <div
          id="About"
          className="pl-5 pt-3 mb-4 text-base text-[#474747] font-normal leading-tight tracking-normal text-left font-roboto"
        >
          Alright, listen up fam, 'cause we got something lit for you: MetaMeme,
          the dankest crypto to hit the scene, inspired by those epic Zuckerberg
          memes we can't stop laughing at. This bad boy's all about that
          blockchain vibe, flipping the script on how we hustle online while
          keeping it mad funny. With MetaMeme, you're not just making bank, you're
          joining the meme elite, riding high on a wave of transparency and
          rock-solid security. So, buckle up, squad, and let's dive deep into the
          memeverse with MetaMeme
        </div>
      </div>
    );
  };