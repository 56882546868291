import React from "react";
import GrayDivisor from "./grayDivisor";

export const Tokenomics : React.FC = () => {
    return (
      <div>
        <GrayDivisor text="Tokenomics" />
        <div id="Tokenomics" className="pl-5 pt-3">
          <div className="flex justify-start gap-5">
            <span className="text-base text-[#B9B3A5] font-normal leading-tight tracking-normal text-left font-roboto">
              Total Supply
            </span>
            <span className="text-[#474747]">10,000,000</span>
          </div>
          <div className="pt-2 flex justify-start gap-5">
            <span className="text-base text-[#B9B3A5] font-normal leading-tight tracking-normal text-left font-roboto">
              No Taxes
            </span>
          </div>
          <div className="pt-2 flex justify-start gap-5">
            <span className="text-base text-[#B9B3A5] font-normal leading-tight tracking-normal text-left font-roboto">
              LP Burned
            </span>
          </div>
          <div className="pt-2 flex justify-start gap-5">
            <span className="text-base text-[#B9B3A5] font-normal leading-tight tracking-normal text-left font-roboto">
              Mint Revoked
            </span>
          </div>
        </div>
      </div>
    );
  };
  