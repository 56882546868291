import React from "react";
import chanelZuck from "../../images/chanelZuck.png";
import bonk from "../../images/bonk.png";
import hobbes from "../../images/hobbes.jpeg";
import wif from "../../images/wif.jpeg";
import ponke from "../../images/ponke.jpeg";

const Contacts: React.FC = () => {
  return (
    <div className="md:max-w-96">
      <div className="mb-4 flex flex-col items-center md:items-start">
        <img src={chanelZuck} alt="Pretty Zuck" className="p-0 m-0 md:w-full" />
        <div className="w-full pt-5 hidden md:block">
          <ContactsDivisor />
        </div>
      </div>
      <div className="hidden md:block">
        <div className="grid grid-cols-3 gap-5">
          <a
            href="https://bonkcoin.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold text-main"
          >
            <img src={bonk} alt="Bonk" />
            $BONK
          </a>
          <a
            href="https://twitter.com/HobbesOnSolana"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold text-main"
          >
            <img src={hobbes} alt="Hobbes" />
            $HOBBES
          </a>
          <a
            href="https://dogwifcoin.org/"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold text-main"
          >
            <img src={wif} alt="Wif" />
            $WIF
          </a>
          <a
            href="https://www.ponke.xyz/"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold text-main"
          >
            <img src={ponke} alt="Ponke" />
            $PONKE
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contacts;

const ContactsDivisor = () => {
  return (
    <div
      style={{
        fontFamily: "sans-serif",
        padding: "8px 8px 8px 8px",
        gap: "10px",
        fontWeight: "700",
        backgroundColor: "#ECEFF6",
        color: "#5A6169",
        display: "flex",
        alignItems: "center",
      }}
    >
      Contacts
    </div>
  );
};
