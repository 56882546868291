import React from "react";

const Logo: React.FC = () => {
  return (
      <svg
        width="216"
        height="28"
        viewBox="0 0 216 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_5_12)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.8293 6.60985C22.0687 3.58128 24.5447 1.19704 27.7108 1.19704C33.6883 1.19704 38.6112 8.80586 38.6136 17.865C38.6136 23.3913 36.2342 26.8542 31.9604 26.8542C28.2369 26.8542 26.1808 24.7644 22.491 18.5914L20.8814 15.8814L20.5967 15.406C20.1769 14.6966 19.7506 13.9911 19.3177 13.2896L16.4749 18.3091C12.4376 25.3677 10.1644 26.8542 7.00794 26.8542C2.62074 26.8542 0 23.4395 0 18.0243C0 9.37297 4.81434 1.19704 11.0959 1.19704C11.8633 1.19543 12.6066 1.29357 13.3257 1.49145C14.0738 1.69899 14.8002 2.02236 15.5289 2.47362C16.9214 3.33996 18.3138 4.6817 19.8293 6.60985ZM23.4877 11.9768C22.8796 10.989 22.2948 10.0776 21.7333 9.24265L21.7188 9.22335C23.758 6.07412 25.4424 4.50795 27.4429 4.50795C31.6009 4.50795 34.9287 10.6302 34.9287 18.1498C34.9287 21.0167 33.9875 22.6794 32.0449 22.6794C30.1795 22.6794 29.289 21.4486 25.7464 15.7535L23.4877 11.9768ZM11.6944 5.43462C13.4439 5.67594 15.0367 6.96459 17.3413 10.2634C16.0115 12.2996 14.6963 14.3453 13.3957 16.4002C10.121 21.5307 8.98917 22.6818 7.16721 22.6818C5.29215 22.6818 4.17484 21.036 4.17484 18.0967C4.17484 11.8175 7.30717 5.3936 11.0428 5.3936C11.2616 5.3936 11.4788 5.40566 11.6944 5.43462Z"
            fill="white"
          />
        </g>
        <path
          d="M59.9781 24.5026L55.7521 14.1561L55.3878 27.6356H50.1053L51.1254 3.37256H56.59L62.419 18.8193L68.2479 3.37256H73.7126L74.7327 27.6356H69.4502L69.0859 14.1561L64.8599 24.5026H59.9781Z"
          fill="white"
        />
        <path
          d="M92.2608 20.6044H82.7159V21.1145C82.7159 23.1546 83.5174 23.7011 85.9218 23.7011C87.5612 23.7011 89.7471 23.4096 91.3136 22.936L92.0787 26.8706C90.4757 27.4535 87.8891 27.9999 85.4118 27.9999C79.6557 27.9999 77.4698 25.1583 77.4698 20.7866V16.6335C77.4698 12.8082 79.1821 9.5294 85.0839 9.5294C90.9857 9.5294 92.2608 13.0268 92.2608 16.8885V20.6044ZM87.3062 16.6699V15.9048C87.3062 14.6662 86.9419 13.6461 85.1203 13.6461C83.2988 13.6461 82.7159 14.6662 82.7159 15.9048V16.6699H87.3062Z"
          fill="white"
        />
        <path
          d="M105.096 27.3442C103.931 27.7449 102.51 27.9999 101.308 27.9999C97.9196 27.9999 95.9523 26.7248 95.9523 23.4461V14.1197H93.5479V9.89371H95.9523V5.63128L101.198 4.90266V9.89371H105.242L104.951 14.1197H101.198V22.7174C101.198 23.4461 101.636 23.7011 102.291 23.7011C102.874 23.7011 103.712 23.6282 104.477 23.3732L105.096 27.3442Z"
          fill="white"
        />
        <path
          d="M121.576 27.6356H117.277L116.913 26.5063C114.873 27.6356 113.051 27.9999 111.849 27.9999C108.57 27.9999 107.222 25.6319 107.222 22.6081C107.222 19.0379 108.862 17.6171 112.505 17.6171H116.33V16.2691C116.33 14.4476 116.002 13.8283 113.197 13.8283C111.63 13.8283 110.064 14.0104 108.388 14.3383L107.696 10.4037C109.372 9.89371 112.031 9.5294 114.071 9.5294C120.228 9.5294 121.576 11.3874 121.576 16.3056V27.6356ZM116.33 23.3003V21.2602H113.816C112.687 21.2602 112.25 21.5516 112.25 22.5353C112.25 23.5189 112.651 23.9197 113.671 23.9197C114.764 23.9197 115.529 23.6646 116.33 23.3003Z"
          fill="white"
        />
        <path
          d="M140.813 27.6356H135.566V15.9048C135.566 15.1033 135.202 14.6297 134.291 14.6297C133.344 14.6297 131.596 15.2855 130.466 15.9048V27.6356H125.22V9.89371H129.118L129.665 11.2417C131.851 10.1852 134.692 9.5294 136.732 9.5294C138.299 9.5294 139.355 10.1487 139.975 11.2417C141.978 10.258 144.638 9.5294 147.079 9.5294C150.102 9.5294 151.159 11.7881 151.159 15.2126V27.6356H145.913V15.9048C145.913 15.1033 145.549 14.6297 144.638 14.6297C143.691 14.6297 141.942 15.2855 140.813 15.9048V27.6356Z"
          fill="white"
        />
        <path
          d="M168.861 20.6044H159.316V21.1145C159.316 23.1546 160.118 23.7011 162.522 23.7011C164.162 23.7011 166.348 23.4096 167.914 22.936L168.679 26.8706C167.076 27.4535 164.49 27.9999 162.012 27.9999C156.256 27.9999 154.07 25.1583 154.07 20.7866V16.6335C154.07 12.8082 155.783 9.5294 161.684 9.5294C167.586 9.5294 168.861 13.0268 168.861 16.8885V20.6044ZM163.907 16.6699V15.9048C163.907 14.6662 163.542 13.6461 161.721 13.6461C159.899 13.6461 159.316 14.6662 159.316 15.9048V16.6699H163.907Z"
          fill="white"
        />
        <path
          d="M187.562 27.6356H182.316V15.9048C182.316 15.1033 181.952 14.6297 181.041 14.6297C180.094 14.6297 178.345 15.2855 177.216 15.9048V27.6356H171.97V9.89371H175.868L176.415 11.2417C178.6 10.1852 181.442 9.5294 183.482 9.5294C185.049 9.5294 186.105 10.1487 186.725 11.2417C188.728 10.258 191.388 9.5294 193.829 9.5294C196.852 9.5294 197.909 11.7881 197.909 15.2126V27.6356H192.663V15.9048C192.663 15.1033 192.298 14.6297 191.388 14.6297C190.44 14.6297 188.692 15.2855 187.562 15.9048V27.6356Z"
          fill="white"
        />
        <path
          d="M215.611 20.6044H206.066V21.1145C206.066 23.1546 206.868 23.7011 209.272 23.7011C210.912 23.7011 213.097 23.4096 214.664 22.936L215.429 26.8706C213.826 27.4535 211.239 27.9999 208.762 27.9999C203.006 27.9999 200.82 25.1583 200.82 20.7866V16.6335C200.82 12.8082 202.532 9.5294 208.434 9.5294C214.336 9.5294 215.611 13.0268 215.611 16.8885V20.6044ZM210.657 16.6699V15.9048C210.657 14.6662 210.292 13.6461 208.471 13.6461C206.649 13.6461 206.066 14.6662 206.066 15.9048V16.6699H210.657Z"
          fill="white"
        />
        <defs>
          <clipPath id="clip0_5_12">
            <rect width="38.3158" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
  );
};

export default Logo;
