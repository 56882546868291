import React from "react";
import Logo from "../logo";
import SearchBar from "../searchBar";
import Button from "../button";

const Header: React.FC = () => {
  return (
    <header className="bg-main pt-3 flex flex-col md:flex-row items-center justify-between">
      <div className="pl-9">
        <Logo />
      </div>
      <div className="bg-secondary pt-2 pl-2 ml-5  flex items-center justify-center xl:flex-grow ">
        <div className="xl:flex hidden w-full justify-normal">
          <SearchBar />
        </div>
        <div className="flex space-x-2">
          <Button label="About" onClick={handleScrollToAbout} />
          <Button label="Tokenomics" onClick={handleScrollToTokenomics} />
          <Button label="Telegram" onClick={openTelegram} />
          <Button label="Twitter" onClick={openTwitter} />
        </div>
      </div>
    </header>
  );
};


export default Header;


const openTwitter = () => {
  window.open('https://twitter.com/MetaMemeSol', '_blank', 'noopener,noreferrer');
};

const openTelegram = () => {
  window.open('https://t.me/metamemesol', '_blank', 'noopener,noreferrer');
}

const handleScrollToAbout = () => {
  document.getElementById('About')?.scrollIntoView({ behavior: 'smooth' });
};

const handleScrollToTokenomics = () => {
  document.getElementById('Tokenomics')?.scrollIntoView({ behavior: 'smooth' });
};