import React from 'react';

interface ButtonProps {
  label: string;
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({ label, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        fontFamily: 'sans-serif',
        fontSize: '20px',
        fontWeight: '700',
        lineHeight: '23.44px',
        letterSpacing: '0.02em',
        textAlign: 'left',
        backgroundColor: 'transparent',
        color: '#FFFFFF', 
        border: 'none',
        outline: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        boxShadow: 'none',
        display: 'inline-block',
      }}
    >
      {label}
    </button>
  );
};

export default Button;
