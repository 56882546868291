import React from "react";

const SearchBar: React.FC = () => {
  return (
    <div className="relative w-[500px] h-[40px]">
      <input
        type="text"
        placeholder="Welcome to metameme"
        readOnly
        className="border border-gray-300 w-full h-full pl-3 rounded-md text-lg focus:outline-none"
      />
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <div
          style={{
            height: "24px", 
            width: "1px",
            backgroundColor: "#AFB4AE",
            marginRight: "4px",
          }}
        />
        <span
          className="material-icons"
          style={{ color: "#AFB4AE" }}
        >
          search
        </span>
      </div>
    </div>
  );
};

export default SearchBar;
