import React from "react";

interface GrayDivisorProps {
  text: string;
}
const GrayDivisor: React.FC<GrayDivisorProps> = ({ text }) => {
  return (
    <div className="p-2 pl-5 gap-2.5 font-bold bg-gray-200 text-gray-600 flex items-center font-roboto ">
      {text}
    </div>
  );
};

export default GrayDivisor;
