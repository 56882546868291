import React from "react";
import { FirstCard } from "./firstCard";
import { About } from "./about";
import { Tokenomics } from "./tokenomics";

const MainContent: React.FC = () => {
  return (
    <div className="relative w-full">
      <div className="mb-4">
        <FirstCard />
      </div>
      <About />
      <Tokenomics />
    </div>
  );
};

export default MainContent;

