import React from "react";
import Logo from "../logo";

const Footer: React.FC = () => {
  return (
    <footer className="bg-[#515F8C] text-white p-4 mt-20">
      <div className="p-9">
        <Logo />
        <div className=" pt-5 text-xs font-normal text-left text-[#FAFAFA] font-roboto">
          @2024 METAMEME. ALL rights reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
