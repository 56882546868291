
export const FirstCard = () => {
    return (
      <div className="relative w-full shadow-md bg-white p-5 ">
        <div className="mb-10">
          <div
            className="font-roboto text-2xl font-[700] text-left text-[#1D2F50]"
            style={{ lineHeight: "32.81px" }}
          >
            MetaMeme
          </div>
          <div
            className="font-roboto text-2xm font-[300] text-left text-[#474747]"
            style={{ lineHeight: "28.13px" }}
          >
            $METAMEME
          </div>
        </div>
  
        <div className="">
          <iframe
            className="w-[100%] h-[50vh]"
            src="https://www.youtube.com/embed/8gY4RR_j1HY?si=PToagEZJqd5jn8Yg&amp;controls=0"
            title="Llama2 token creation"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    );
  };
  