import React from "react";
import Header from "./components/header";
import "./App.css";
import Contacts from "./components/contacts";
import MainContent from "./components/mainContent";
import Footer from "./components/footer";

function App() {
  return (
    <div className="">
      <Header />
      <div className="absolute bg-[#eef4f1] h-24 w-full -z-10"></div>
      <div className=" flex flex-col md:flex-row md:pl-10 md:pr-10 p-4 md:pt-10">
        <div className="md:w-1/4">
          <Contacts />
        </div>
        <div className="md:w-3/4">
          <div className="flex">
            <div className="flex items-center w-full md:w-[600px] md:ml-[10vw]" >
              <MainContent />
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
